import React, { Component } from 'react';
import '../App.css';
import owner from '../assets/image2.png';

class Utility extends Component {


    render() {
        return (

            <div class="boxWrap3">

                <div data-aos="fade-up" class="UtilDiv">
                    <img class="storyPic" src={owner} />
                </div>

                <div data-aos="fade-up" class="uR">
                    <div class="storyH">Why I started my project!</div>
                    <p class="storyCon">I have previously served as a full time firefighter in GMFRS and due to my mental health issues I had to leave the job I loved and became to spiral out of control. Everytime I tried to speak up about how I felt I was given the sentence 'man up'</p>
                    <p class="storyCon">I felt like I had no where to turn and there was a stigma behind this. My goal for this project is to help raise awareness of mental health issues and specifically male suicide. </p>
                    <p class="storyCon">25% of profits go to charity to help people with mental health issues and 50% of secondary sales go to the same charity. </p>


                </div>

               

                <div data-aos="fade-up" class="uRMobile">
                    <div class="storyH">Why I started my project!</div>
                    <p class="storyCon">I have previously served as a full time firefighter in GMFRS and due to my mental health issues I had to leave the job I loved and became to spiral out of control. Everytime I tried to speak up about how I felt I was given the sentence 'man up'</p> 
                    <p class="storyCon">I felt like I had no where to turn and there was a stigma behind this. My goal for this project is to help raise awareness of mental health issues and specifically male suicide. </p>
                    <p class="storyCon">25% of profits go to charity to help people with mental health issues and 50% of secondary sales go to the same charity. </p>
                </div>

                <div data-aos="fade-up" class="storyPicDivMobile">
                    <img class="storyPic" src={owner} />
                </div>


            </div>


        );

    }
}

export default Utility;