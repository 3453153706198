import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import NFTs from '../assets/image.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story extends Component {




    render() {
        return (

            <div class="boxWrap2">               

                <div data-aos="fade-up" class="storyRight">
                    <div class="storyH">Boys Club Official</div>
                    <p class="storyCon">Boys club official is a collection of 2222 NFTS on the etherium Blockchain priced at 0.02 eth</p>
                    <p class="storyCon">We have 2 further collections planned and will tackle mental health issues in women again giving 25% of profits to the selected charity and 50% of secondary sales.</p>
                    <p class="storyCon">Our final collection will be 100% for charity and every single penny raised will be going to charity</p>    
                </div>

                <div data-aos="fade-up" class="storyPicDiv">
                    <img class="storyPic" src={NFTs}/>
                </div>

            </div>

        )
    }
}

export default Story;

