import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {


	render() {

		return (

			<div class="footer">

				<div class="right2">

					<div class="discordF">
						<SocialIcon class="discord" url="https://discord.com/" target="_blank" network="discord" bgColor="#ffffff" style={{ height: 25, width: 25 }} />
					</div>
					<div class="discordF">
						<SocialIcon class="discord" url="https://twitter.com/" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 25, width: 25 }} />
					</div>

					<div class="discord3">
						<SocialIcon class="discord" url="https://discord.com/" target="_blank" network="discord" bgColor="#ffffff" style={{ height: 35, width: 35 }} />
					</div>
					<div class="discord3">
						<SocialIcon class="discord" url="https://twitter.com/" target="_blank" network="twitter" bgColor="#ffffff" style={{ height: 35, width: 35 }} />
					</div>

				</div>
				<div class="copyright">Copyright © 2022, Boys club official</div>
				<div class="copyright">All trademarks and copyrights belong to their respective owners.</div>

			</div>)
	}
}

export default Footer;