import React, { Component } from 'react';
import '../components/styles/carousel.css';
import d1 from '../assets/1.png';
import d2 from '../assets/2.png';
import d3 from '../assets/3.png';
import d4 from '../assets/4.png';
import d5 from '../assets/5.png';
import d6 from '../assets/6.png';
import d7 from '../assets/7.png';
import d8 from '../assets/8.png';
import d9 from '../assets/9.png';

class Carousel extends Component {

    render() {
        return (

            <div>

                <div class="slider">
                    <div class="slide-track">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>                        
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                     <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>                        
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>     
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>  
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;        